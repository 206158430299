import { HTTP } from '../../../http-common'
import helper from '../index'

export default {
  addOpportunity: async ({
                           need,
                           memberId,
                           address,
                           city,
                           state,
                           zip_code,
                           contactName,
                           contactPhone,
                           contactEmail,
                           specialAttire,
                           entryInstructions,
                           otherDetails,
                           description,
                           volunteersRequired,
                           hoursPerVolunteer,
                           startHour,
                           endHour,
                           timeZone,
                           start,
                           recurrent,
                           end,
                           weekDay,
                           needAuth,
                           frequency
                         }) => {
    await HTTP.post(
      'opportunities',
      {
        need,
        memberId,
        address,
        city,
        state,
        zip_code,
        contactName,
        contactPhone,
        contactEmail,
        specialAttire,
        entryInstructions,
        otherDetails,
        description,
        recurrent,
        volunteersRequired,
        hoursPerVolunteer,
        startHour,
        endHour,
        timeZone,
        start,
        end,
        weekDay,
        needAuth,
        frequency
      },
      {
        headers: helper.setHeaders()
      }
    )
  },
  editDates: async ({
                      date
                    }) => {
    await HTTP.put(
      '/opportunities/dates/update',
      { date },
      {
        headers: helper.setHeaders()
      })
  },
  createDate: async ({ id, hours }) => {
    const response = await HTTP.put(
      '/opportunities/date/create',
      { id, hours },
      {
        headers: helper.setHeaders()
      })
    return response.data.data
  },
  deleteDate: async ({
                       id
                     }) => {
    const response = await HTTP.put(
      '/opportunities/date/delete',
      { id },
      {
        headers: helper.setHeaders()
      })
    return response.data
  },
  editOpportunity: async ({
                            id,
                            need,
                            memberId,
                            address,
                            city,
                            state,
                            zip_code,
                            contactName,
                            contactPhone,
                            contactEmail,
                            specialAttire,
                            entryInstructions,
                            otherDetails,
                            description,
                            volunteersRequired,
                            hoursPerVolunteer,
                            startHour,
                            recurrent,
                            endHour,
                            timeZone,
                            start,
                            end,
                            weekDay,
                            needAuth,
                            frequency
                          }) => {
    await HTTP.put(
      'opportunities/' + id,
      {
        need,
        memberId,
        address,
        city,
        state,
        zip_code,
        contactName,
        contactPhone,
        contactEmail,
        specialAttire,
        entryInstructions,
        otherDetails,
        description,
        volunteersRequired,
        hoursPerVolunteer,
        startHour,
        endHour,
        recurrent,
        timeZone,
        start,
        end,
        weekDay,
        needAuth,
        frequency
      },
      {
        headers: helper.setHeaders()
      }
    )
  },
  enableDisableOpportunity: async ({ id }) => {
    await HTTP.put(
      'opportunities/enable-disable',
      { id },
      {
        headers: helper.setHeaders()
      }
    )
  },
  enableDisableOpportunities: async ({ opportunities }) => {
    await HTTP.put(
      'opportunities/enable-disable-multiple',
      { opportunities },
      {
        headers: helper.setHeaders()
      }
    )
  },
  deleteOpportunity: async ({ id }) => {
    await HTTP.put(
      'opportunities/delete',
      { id },
      {
        headers: helper.setHeaders()
      }
    )
  },
  deleteOpportunities: async ({ opportunities }) => {
    await HTTP.put(
      'opportunities/delete-multiple',
      { opportunities },
      {
        headers: helper.setHeaders()
      }
    )
  },
  disableOpportunity: async ({ id }) => {
    await HTTP.delete('opportunities/disable/' + id,
      {
        headers: helper.setHeaders()
      }
    )
  },
  getOpportunities: async () => {
    const response = await HTTP.get('opportunities', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getCharityOpportunities: async (charityId) => {
    const res = await HTTP.get(`/opportunities/${ charityId }`, {
      headers: helper.setHeaders()
    })
    return res.data.data
  },
  getSuspendedOpportunities: async () => {
    const response = await HTTP.get('opportunities/suspended', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getMembers: async () => {
    const response = await HTTP.get('opportunities/members', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getOpportunitiesByMember: async ({ id }) => {
    const response = await HTTP.get('members/' + id + '/opportunities', {
      headers: helper.setHeaders()
    })
    return response.data.data
  }
}
