import {HTTP} from '../../../http-common'
import helper from '../index'

export default {
  getVolunteers: async({id}) => {
    const response = await HTTP.get('opportunities/' + id + '/volunteers', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  getAllVolunteers: async() => {
    const response = await HTTP.get('opportunities/all/volunteers', {
      headers: helper.setHeaders()
    })
    return response.data.data
  },
  setAttendace: async({id, attendance}) => {
    await HTTP.put(
      'opportunities/' + id + '/volunteers/attendace',
      {attendance: attendance},
      {
        headers: helper.setHeaders()
      }
    )
  },
  setVerification: async({id, verification, user_id}) => {
    await HTTP.put(
      'opportunities/' + id + '/volunteers/verification',
      {verification, user_id},
      {
        headers: helper.setHeaders()
      }
    )
  }
}
