<template>
  <div v-frag>
    <v-card class='pa-10 ma-6 elevation-2'>
      <v-data-table
        :headers='opportunityHeaders'
        :items='opportunity'
        class='elevation-1 mb-10 my-table'
        hide-default-footer
      >
        <template v-slot:top>
          <v-dialog
            v-model='dialog'
            max-width='1000px'
            scrollable
            transition='dialog-bottom-transition'
          >
            <v-form ref='form' v-model='valid'>
              <v-card>
                <v-card-title>
                  <span class='headline font-weight-bold'
                  >Edit Opportunity</span
                  >
                </v-card-title>
                <v-card-text class='py-2 px-4'>
                  <v-container class='pa-6'>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Need</p>
                        <v-text-field
                          v-model='editedItem.need'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e Food Drive'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Company</p>
                        <v-select
                          v-model='editedItem.member'
                          :items='members'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          item-text='name'
                          item-value='id'
                          label='Select a company'
                          outlined
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Address</p>
                        <v-text-field
                          v-model='editedItem.address'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e Joystreet 123'
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'>Description</p>
                        <v-textarea
                          v-model='editedItem.description'
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          placeholder='i.e Describe the need of the volunteer opportunity in detail here.'
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row class='mb-n10' md='4' sm='6'>
                      <v-col>
                        <p class='font-weight-bold'># of Volunteers Needed</p>
                        <v-text-field
                          v-model='editedItem.spots'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          outlined
                          placeholder='i.e 20'
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Start Time</p>
                        <vue-timepicker
                          v-model='editedItem.startHour'
                          :minute-interval='15'
                          :rules="[(v) => !!v || 'Field is required']"
                          auto-scroll
                          format='h:mm A'
                          @change='calculateHoursPerVolunteer'
                        ></vue-timepicker>

                        <!-- <v-menu
                          ref="startingTimeMenu"
                          v-model="startingTimeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.startHour"
                              prepend-inner-icon="mdi-clock-time-four-outline"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="startingTimeMenu"
                            :allowed-minutes="allowedStep"
                            ampm-in-title
                            v-model="editedItem.startHour"
                            full-width
                            @click:minute="$refs.startingTimeMenu.save(time)"
                          ></v-time-picker>
                        </v-menu> -->
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>End Time</p>
                        <vue-timepicker
                          v-model='editedItem.endHour'
                          :minute-interval='15'
                          :rules="[(v) => !!v || 'Field is required']"
                          auto-scroll
                          format='h:mm A'
                          @change='calculateHoursPerVolunteer'
                        ></vue-timepicker>
                        <!-- <v-menu
                          ref="endingTimeMenu"
                          v-model="endingTimeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="time"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.endHour"
                              prepend-inner-icon="mdi-clock-time-four-outline"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="endingTimeMenu"
                            v-model="editedItem.endHour"
                            full-width
                            :allowed-minutes="allowedStep"
                            ampm-in-title
                            @click:minute="
                              $refs.endingTimeMenu.save(editedItem.endHour)
                            "
                          ></v-time-picker>
                        </v-menu> -->
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Time Zone</p>
                        <v-select
                          v-model='editedItem.timeZone'
                          :items='timeZones'
                          :rules="[(v) => !!v || 'Field is required']"
                          dense
                          item-text='description'
                          item-value='id'
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col>
                        <p class='font-weight-bold'>Hours per Volunteer</p>
                        <v-text-field
                          v-model='editedItem.hoursPerVolunteer'
                          :rules="[(v) => !!v || 'Field is required']"
                          background-color='#E6E6E6'
                          dense
                          outlined
                          placeholder='i.e 8'
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class='mt-4' md='6'>
                        <p class='font-weight-bold'>Start Date</p>
                        <v-menu
                          ref='menuStartDate'
                          v-model='menuStartDate'
                          :close-on-content-click='false'
                          :return-value.sync='editedItem.start'
                          min-width='auto'
                          offset-y
                          transition='scale-transition'
                        >
                          <template v-slot:activator='{ on, attrs }'>
                            <v-text-field
                              v-model='editedItem.start'
                              :rules="[(v) => !!v || 'Field is required']"
                              dense
                              outlined
                              prepend-inner-icon='mdi-calendar'
                              readonly
                              v-bind='attrs'
                              v-on='on'
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model='editedItem.start'
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn color='primary' text @click='menu = false'>
                              Cancel
                            </v-btn>
                            <v-btn
                              color='primary'
                              text
                              @click='
                                $refs.menuStartDate.save(editedItem.start)
                              '
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row class='d-flex flex-row align-center px-4'>
                      <h3 class='font-weight-bold pa-0 ma-0'>
                        Require background check?
                      </h3>
                      <v-switch
                        v-model='editedItem.needAuth'
                        class='mx-4 mt-4'
                      ></v-switch>
                    </v-row>
                    <v-row class='d-flex flex-row align-center px-4 mb-10'>
                      <h3 class='font-weight-bold pa-0 ma-0'>
                        Is this a recurring volunteering opportunity?
                      </h3>
                      <v-switch
                        v-model='editedItem.recurrent'
                        class='mx-4 mt-4'
                      ></v-switch>
                    </v-row>
                    <v-container v-if='editedItem.recurrent' class='pa-0 ma-0'>
                      <v-row class='px-6 mt-4'>
                        <v-row>
                          <p class='font-weight-bold'>
                            Select the volunteering's corresponding days of the
                            week
                          </p>
                        </v-row>
                        <v-row
                          class='
                            d-flex
                            flex-row
                            justify-space-between
                            px-4
                            mt-n4
                          '
                        >
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            value='Monday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Monday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            value='Tuesday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Tuesday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            value='Wednesday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Wednesday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            value='Thursday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Thursday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            value='Friday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Friday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            value='Saturday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Saturday</p>
                            </template>
                          </v-checkbox>
                          <v-checkbox
                            v-model='editedItem.weekDay'
                            value='Sunday'
                          >
                            <template v-slot:label>
                              <p class='mt-4'>Sunday</p>
                            </template>
                          </v-checkbox>
                        </v-row>
                      </v-row>
                      <v-row class='px-4 mt-4 d-flex direction-row'>
                        <v-col md='6'>
                          <p class='font-weight-bold'>
                            Select the volunteering's recurrence.
                          </p>
                          <v-row
                            class='
                              px-4
                              mt-4
                              d-flex
                              flex-row
                              align-center
                              justify-start
                            '
                          >
                            <v-radio-group
                              v-model='editedItem.frequency'
                              :rules="[(v) => !!v || 'Field is required']"
                              row
                            >
                              <v-radio label='Weekly' value='Weekly'></v-radio>
                              <v-radio
                                label='Biweekly'
                                value='Biweekly'
                              ></v-radio>
                              <v-radio
                                label='Once a month'
                                value='Monthly'
                              ></v-radio>
                            </v-radio-group>
                          </v-row>
                        </v-col>
                        <v-col md='6'>
                          <p class='font-weight-bold'>End Date</p>
                          <v-menu
                            ref='menuEndDate'
                            v-model='menuEndDate'
                            :close-on-content-click='false'
                            :return-value.sync='editedItem.end'
                            min-width='auto'
                            offset-y
                            transition='scale-transition'
                          >
                            <template v-slot:activator='{ on, attrs }'>
                              <v-text-field
                                v-model='editedItem.end'
                                :rules='[validateEndDate]'
                                dense
                                outlined
                                prepend-inner-icon='mdi-calendar'
                                readonly
                                v-bind='attrs'
                                @change='validateEndDate()'
                                v-on='on'
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model='editedItem.end'
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn color='primary' text @click='menu = false'>
                                Cancel
                              </v-btn>
                              <v-btn
                                color='primary'
                                text
                                @click='$refs.menuEndDate.save(editedItem.end)'
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-container>
                </v-card-text>
                <v-card-actions class='d-flex direction-row justify-center'>
                  <v-btn
                    class='px-14 ma-4 font-weight-bold'
                    color='primary'
                    large
                    outlined
                    @click='close'
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled='!valid'
                    class='px-14 ma-4 font-weight-bold white--text'
                    color='primary'
                    large
                    @click='save'
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </template>
        <template v-slot:[`item.hours`]='{ item }'>
          <span
          >{{ item.startHour }} - {{ item.endHour }}
            <span v-if="item.timeZone !== ''"> ({{ item.timeZone }}) </span>
          </span>
        </template>
        <template v-slot:[`item.frequency`]='{ item }'>
          <span>{{ item.frequency }}</span>
        </template>
      </v-data-table>

      <v-row class='pa-4 mx-1'>
        <v-col md='8'>
          <v-data-table
            :headers='descriptionHeader'
            :items='opportunity'
            class='elevation-1 my-table'
            hide-default-footer
          >
          </v-data-table>
        </v-col>
        <v-col class='d-flex flex-column justify-space-between px-14'>
          <v-card
            class='d-flex flex-row justify-space-around align-center mb-10'
          >
            <p class='pt-4 headline font-weight-bold'>Total Hours</p>
            <v-divider vertical></v-divider>
            <p class='pt-4 headline font-weight-bold'>{{ totalHours }}</p>
          </v-card>
          <v-btn
            class='text-capitalize font-weight-bold py-6'
            color='primary'
            outlined
            @click='openEditOpportunity'
          >
            Edit Opportunity
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-data-table
      v-model='selected'
      :headers='volunteersHeader'
      :items='volunteers'
      :search='search'
      checkboxColor='primary'
      class='elevation-2 pa-4 ma-6'
      item-key='id'
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class='font-weight-bold'
          >Employee Applications
          </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model='search'
            class='mr-4'
            dense
            hide-details
            label='Search'
            outlined
            prepend-inner-icon='mdi-magnify'
            single-line
          >
          </v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.employee.fullName`]='{ item }'>
        <span
          class='text-decoration-underline'
          @click='onEmployeeClick(item)'
        >{{ item.employee.fullName }}</span
        >
      </template>
      <template v-slot:[`item.member`]='{ item }'>
        <span>{{ item.member.name }}</span>
      </template>
      <template v-slot:[`item.verification`]='{ item }'>
        <v-select
          v-model='item.verification'
          :disabled="
            item.verification == 'Rejected' ||
            item.verification == 'Acknowledged'
          "
          :items='statuses'
          class='mt-4'
          dense
          item-text='status'
          item-value='status'
          @change='setVerification(item)'
        >
          <template v-slot:selection>
            <v-chip
              v-if="item.verification == 'Pending'"
              class='warning px-16 py-4 mb-2 text-uppercase'
            >
              <span>Pending</span>
            </v-chip>
            <v-chip
              v-if="item.verification == 'Rejected'"
              class='error px-16 py-4 mb-2 text-uppercase'
            >
              <span>Rejected</span>
            </v-chip>
            <v-chip
              v-if="item.verification == 'Acknowledged'"
              class='success px-16 py-4 mb-2 text-uppercase'
            >
              <span>Acknowledged</span>
            </v-chip>
          </template>
        </v-select>
      </template>
      <template v-slot:[`item.attendance`]='{ item }'>
        <v-select
          v-model='item.attendance'
          :disabled='item.disableAttendance'
          :items='attendance'
          class='mt-4'
          dense
          item-text='attendance'
          item-value='attendance'
          outlined
          @change='setAttendance(item)'
        >
          <template v-slot:selection>
            <v-chip v-if="item.attendance === 'Yes'" class='white py-4'>
              <span class='success--text font-weight-bold'>Yes</span>
            </v-chip>
            <v-chip v-if="item.attendance == 'No'" class='white py-4'>
              <span class='error--text font-weight-bold'>No</span>
            </v-chip>
            <v-chip v-if="item.attendance == 'Pending'" class='white py-4'>
              <span class='warning--text font-weight-bold'>Pending</span>
            </v-chip>
          </template>

          <template v-slot:no-data>
            <span>There are no registered volunteers for this opportunity</span>
          </template>
        </v-select>
      </template>
    </v-data-table>
    <v-dialog
      v-model='employeeDialog'
      max-width='1000px'
      transition='dialog-bottom-transition'
    >
      <v-card class='py-6'>
        <v-card-title>
          <span class='headline font-weight-bold'>Employee Details</span>
        </v-card-title>

        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-row class='mb-n10' md='4' sm='6'>
              <v-col>
                <p class='font-weight-bold'>First Name</p>
                <v-text-field
                  v-model='employeeItem.firstName'
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <p class='font-weight-bold'>Middle Name</p>
                <v-text-field
                  v-model='employeeItem.middleName'
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class='mb-n10' md='4' sm='6'>
              <v-col>
                <p class='font-weight-bold'>Last Name</p>
                <v-text-field
                  v-model='employeeItem.lastName'
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <p v-if='opportunity[0].needAuth' class='font-weight-bold'>
                  Address
                </p>
                <v-text-field
                  v-if='opportunity[0].needAuth'
                  v-model='employeeItem.address'
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class='mb-n10' md='4' sm='6'>
              <v-col>
                <p class='font-weight-bold'>Phone Number</p>
                <v-text-field
                  v-model='employeeItem.phoneNumber'
                  :rules='phoneRules'
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <p v-if='opportunity[0].needAuth' class='font-weight-bold'>
                  Birth Year
                </p>
                <v-text-field
                  v-if='opportunity[0].needAuth'
                  v-model='employeeItem.birthDate'
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class='mb-n10' md='4' sm='6'>
              <v-col md='6'>
                <p class='font-weight-bold'>Email address</p>
                <v-text-field
                  v-model='employeeItem.email'
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class='d-flex align-center justify-center'>
              <v-btn
                class='mt-4 px-10'
                color='primary'
                outlined
                @click='close()'
              >Close
              </v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import opportunity from '../../services/opportunities'
import volunteers from '../../services/volunteers'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  components: {VueTimepicker},
  data: () => ({
    loading: true,
    dialog: false,
    valid: true,
    members: [],
    time: null,
    employeeDialog: false,
    startingTimeMenu: false,
    timeZones: ['PT', 'MT', 'CST', 'CT', 'ET'],
    endingTimeMenu: false,
    menuStartDate: false,
    totalHours: Number,
    menuEndDate: false,
    singleSelect: true,
    search: '',
    selected: [],
    opportunity: [],
    volunteers: [],
    phoneRules: [
      v => /^\d+$/.test(v) || "Value must be a number",
      v => v.length <= 10 || "Value must be equal to 10 characters",
      v => v.length >= 10 || "Value must be equal to 10 characters",
    ],
    opportunityHeaders: [
      {
        text: 'Opportunity',
        align: 'start',
        sortable: false,
        value: 'need'
      },
      {
        text: 'Volunteers Req.',
        sortable: false,
        value: 'spots'
      },
      {
        text: 'Hs. per Vol.',
        value: 'hoursPerVolunteer'
      },
      {
        text: 'Frequency',
        value: 'frequency'
      },
      {
        text: 'Hours Range',
        value: 'hours'
      },
      {
        text: 'Start',
        value: 'start'
      },
      {
        text: 'End',
        value: 'end'
      }
    ],
    statuses: [
      {status: 'Pending', id: 1},
      {status: 'Rejected', id: 2},
      {status: 'Acknowledged', id: 3}
    ],
    attendance: [
      {attendance: 'Yes', id: 'yes'},
      {attendance: 'No', id: 'no'},
      {attendance: 'Pending', id: 'pending'}
    ],
    descriptionHeader: [{text: 'Description', value: 'description'}],
    volunteersHeader: [
      {
        text: 'Employee',
        align: 'start',
        sortable: true,
        value: 'employee.fullName'
      },
      {
        text: 'Company',
        sortable: true,
        value: 'member.name'
      },
      {
        text: 'Date',
        sortable: true,
        value: 'date'
      },
      {
        text: 'Hours',
        sortable: true,
        value: 'hours'
      },

      {
        text: 'Attendance',
        sortable: true,
        value: 'attendance',
        width: '12%'
      }
    ],
    editedItem: {
      id: '',
      need: '',
      member: '',
      address: '',
      description: '',
      spots: Number,
      hoursPerVolunteer: Number,
      startHour: '',
      endHour: '',
      timeZone: '',
      start: '',
      end: '',
      recurrent: false,
      dayOfWeek: '',
      recurrencyType: '',
      weekDay: []
    },
    defaultItem: {
      id: '',
      need: '',
      member: '',
      address: '',
      description: '',
      spots: 0,
      hoursPerVolunteer: 0,
      startHour: '',
      endHour: '',
      timeZone: '',
      start: '',
      end: '',
      recurrent: false,
      recurrencyType: '',
      weekDay: []
    },
    employeeItem: {
      id: '',
      birthDate: '',
      email: '',
      firstName: '',
      secondName: '',
      lastName: '',
      hierarchy: {},
      phoneNumber: '',
      reportsTo: {},
      userName: '',
      vuid: ''
    }
  }),
  computed: {},
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.getOpportunity()
      this.getMembers()
      this.getVolunteers()
    },
    allowedStep: (m) => m % 5 === 0,
    openEditOpportunity() {
      this.editedItem = this.opportunity[0]
      this.dialog = true
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    enableDisableAttendance() {
      const currentDate = new Date()
      this.volunteers.forEach((volunteer) => {
        const stringifyDate = String(volunteer.date)
        let volunteeringDate = new Date(stringifyDate)
        if (volunteeringDate > currentDate) {
          volunteer.disableAttendance = true
        } else if (volunteeringDate < currentDate) {
          volunteer.disableAttendance = false
        } else {
          volunteer.disableAttendance = false
        }
      })
    },
    validateEndDate() {
      const endDate = new Date(this.editedItem.end)
      const startDate = new Date(this.editedItem.start)

      if (this.editedItem.end && endDate > startDate) {
        return true
      }
      if (endDate <= startDate) {
        return 'Ending date must be after Starting Date'
      } else {
        return 'Field is Required'
      }
    },

    save() {
      this.$refs.form.validate()
      Object.assign(this.opportunity[0], this.editedItem)
      if (!this.editedItem.recurrent) {
        this.editedItem.weekDay = []
        this.editedItem.frequency = ''
        this.editedItem.end = ''
      }
      if (this.editedItem.member.name == 'All') {
        this.editedItem.member = this.editedItem.member.id
      }
      this.$store.dispatch('opportunity', this.editedItem)
      this.editOpportunity()
      this.close()
    },
    async editOpportunity() {
      try {
        await opportunity.editOpportunity({
          id: this.editedItem.id,
          need: this.editedItem.need,
          memberId: this.editedItem.member,
          address: this.editedItem.address,
          description: this.editedItem.description,
          volunteersRequired: this.editedItem.spots,
          hoursPerVolunteer: this.editedItem.hoursPerVolunteer,
          startHour: this.editedItem.startHour,
          endHour: this.editedItem.endHour,
          timeZone: this.editedItem.timeZone,
          start: this.editedItem.start,
          end: this.editedItem.end,
          weekDay: this.editedItem.weekDay,
          frequency: this.editedItem.frequency,
          needAuth: this.editedItem.needAuth
        })
        this.getOpportunity()
      } catch (err) {
        console.log(err)
      }
    },
    getOpportunity() {
      const opportunity = this.$store.getters.opportunity
      this.opportunity[0] = opportunity

      const headerAuth = {
        text: 'Background Check',
        sortable: true,
        value: 'verification',
        width: '20%'
      }

      if (this.opportunity[0].needAuth === true) {
        this.volunteersHeader.splice(4, 0, headerAuth)
      } else {
        this.volunteersHeader.forEach((header) => {
          if (header.value == headerAuth.value) {
            this.volunteersHeader.splice(4, 1)
          }
        })
      }
      // const hourArray = opportunity.hoursPerVolunteer.split(":");
      // const hours = parseInt(hourArray[0]);
      // const minutes = parseInt(hourArray[1]) / 60;
      // this.totalHours = parseInt(opportunity.spots) * (hours + minutes);

      this.totalHours =
        parseFloat(opportunity.spots) *
        parseFloat(opportunity.hoursPerVolunteer)
    },
    onEmployeeClick(item) {
      this.employeeItem = Object.assign({}, item.employee)
      this.employeeItem.reportsTo =
        item.employee.reportsTo.firstName +
        ' ' +
        item.employee.reportsTo.lastName
      this.employeeItem.hierarchy = item.employee.hierarchy.description
      this.employeeDialog = true
    },
    calculateHoursPerVolunteer() {
      let start = this.editedItem.startHour.valueOf()
      let end = this.editedItem.endHour.valueOf()

      let timeStart = new Date('01/01/2021 ' + start)

      let timeEnd = new Date('01/01/2021 ' + end)


      let hourDiffinMS = timeEnd - timeStart
      let hours = Math.floor(hourDiffinMS / (1000 * 60 * 60))


      // let msToHours = this.parseMillisecondsIntoReadableTime(hourDiff);
      // console.log(msToHours);

      // this.editedItem.hoursPerVolunteer = msToHours;
      this.editedItem.hoursPerVolunteer = hours
    },

    // parseMillisecondsIntoReadableTime(milliseconds) {
    //   let hours = milliseconds / (1000 * 60 * 60);
    //   let absoluteHours = Math.floor(hours);
    //   let h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

    //   let minutes = (hours - absoluteHours) * 60;
    //   let absoluteMinutes = Math.floor(minutes);
    //   let m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

    //   return h + ":" + m;
    // },
    async getMembers() {
      try {
        this.members = await opportunity.getMembers()
        this.members.unshift({
          name: 'All',
          id: 'all'
        })
        this.loadingSuspended = false
      } catch (err) {
        console.log(err)
      }
    },

    async getVolunteers() {
      try {
        this.volunteers = await volunteers.getVolunteers({
          id: this.opportunity[0].id
        })
        this.enableDisableAttendance()
      } catch (err) {
        console.log(err)
      }
    },
    async setAttendance(item) {
      try {
        await volunteers.setAttendace({
          id: item.id,
          attendance: item.attendance
        })
      } catch (err) {
        console.log(err)
      }
    },
    async setVerification(item) {
      try {
        const user_id = this.$store.getters.user.id
        await volunteers.setVerification({
          id: item.id,
          verification: item.verification,
          user_id: user_id
        })
      } catch (err) {
        console.log(err)
      }
    },

    close() {
      this.dialog = false
      this.employeeDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.my-table.v-data-table :deep(th) {
  font-size: 16px !important;
  font-weight: bold;
}
</style>
<style lang='scss'>
.vue__time-picker input.display-time {
  border: 2px solid #a7a7a7 !important;
  width: 10em !important;
  height: 2.4em !important;
  padding: 0.3em 0.5em !important;
  font-size: 1.2em !important;
  border-radius: 5px !important;
  &:focus-visible {
    outline-color: rgb(92 140 215);
  }
}
</style>
